/*
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url(/fonts/NotoSansKr/NotoSansKR-Thin.woff2) format('woff2'),
       url(/fonts/NotoSansKr/NotoSansKR-Thin.woff) format('woff'),
       url(/fonts/NotoSansKr/NotoSansKR-Thin.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url(/fonts/NotoSansKr/NotoSansKR-Light.woff2) format('woff2'),
       url(/fonts/NotoSansKr/NotoSansKR-Light.woff) format('woff'),
       url(/fonts/NotoSansKr/NotoSansKR-Light.otf) format('opentype');
}


@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 100;
    src: local('Noto Sans Thin'), local('Noto Sans Thin'), local('Noto Sans Thin');
    src: url(NotoSansKR-Thin-subset.eot);
    src: url(NotoSansKR-Thin-subset.eot?#iefix) format('embedded-opentype'),
    url(NotoSansKR-Thin-subset.woff) format('woff'),
    url(NotoSansKR-Thin-subset.otf) format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Noto Sans Light'), local('Noto Sans Light'), local('Noto Sans Light');
    src: url(NotoSansKR-Light-subset.eot);
    src: url(NotoSansKR-Light-subset.eot?#iefix) format('embedded-opentype'),
    url(NotoSansKR-Light-subset.woff) format('woff'),
    url(NotoSansKR-Light-subset.otf) format('truetype');
}
*/

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 100;
    src: local('Noto Sans Thin'), local('Noto Sans Thin'), local('Noto Sans Thin');
    src: url(NotoSans-Thin.eot);
    src: url(NotoSans-Thin.eot?#iefix) format('embedded-opentype'),
    url(NotoSans-Thin.woff) format('woff'),
    url(NotoSans-Thin.otf) format('truetype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 300;
    src: local('Noto Sans Light'), local('Noto Sans Light'), local('Noto Sans Light');
    src: url(NotoSans-Light.eot);
    src: url(NotoSans-Light.eot?#iefix) format('embedded-opentype'),
    url(NotoSans-Light.woff) format('woff'),
    url(NotoSans-Light.otf) format('truetype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: local('Noto Sans Regular'), local('Noto Sans Regular'), local('Noto Sans Regular');
    src: url(NotoSans-Regular.eot);
    src: url(NotoSans-Regular.eot?#iefix) format('embedded-opentype'),
    url(NotoSans-Regular.woff) format('woff'),
    url(NotoSans-Regular.otf) format('truetype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src: local('Noto Sans Medium'), local('Noto Sans Medium'), local('Noto Sans Medium');
    src: url(NotoSans-Medium.eot);
    src: url(NotoSans-Medium.eot?#iefix) format('embedded-opentype'),
    url(NotoSans-Medium.woff) format('woff'),
    url(NotoSans-Medium.otf) format('truetype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: local('Noto Sans Bold'), local('Noto Sans Bold'), local('Noto Sans Bold');
    src: url(NotoSans-Bold.eot);
    src: url(NotoSans-Bold.eot?#iefix) format('embedded-opentype'),
    url(NotoSans-Bold.woff) format('woff'),
    url(NotoSans-Bold.otf) format('truetype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 900;
    src: local('Noto Sans Black'), local('Noto Sans Black'), local('Noto Sans Black');
    src: url(NotoSans-Black.eot);
    src: url(NotoSans-Black.eot?#iefix) format('embedded-opentype'),
    url(NotoSans-Black.woff) format('woff'),
    url(NotoSans-Black.otf) format('truetype');
}
