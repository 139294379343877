$buttonControlWidth: 36px;
$buttonControlHeight: 36px;

.section-hotkey {
  display: none;
  flex: none;
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  padding: 29px 0 30px;
  color: #fff;
  background: #2f2f2f;

  &[data-show="true"] {
    display: block;
  }

  > .inner {
    width: 914px;
    margin: 0 auto;

    > .title {
      font-size: 18px;
      font-weight: 500;
    }

    > .wrap {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }
}

.button-modal-hotkey-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  background: transparent;

  &:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background: url('../../img/button/button_modal_close.svg') no-repeat center center;
  }

  &:hover {
    &:before {
      opacity: 0.5;
    }
  }
}

[class*="wrap-keystroke-"] {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  .stitle {
    flex: none;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid $textColor02;
    font-size: 12px;
    color: $textColor02;
    letter-spacing: 0.18px;
  }
}

.wrap-keystroke-text {
  padding: 0 10px;
}

// 편집 모드인 경우
.section-hotkey.edit-mode {
  .shortcut-item {
    &:not(.fixed) {
      cursor: pointer;

      .keystroke {
        background-color: #fff;
        border-color: #d9d9d9;
        box-shadow: 0 2px 0 rgba(0, 0, 0, .02);
        color: rgba(0, 0, 0, .88);
      }

      &:hover {
        .term {
          color: #4096ff;

        }

        .keystroke {
          color: #4096ff;
          border-color: #4096ff;
        }
      }
    }
  }
}

.wrap-shortcut-item {;
  display: table;

  &:not(:nth-of-type(1)) {
    margin-left: 20px;
  }

  .shortcut-item {
    display: table-row;
    white-space: nowrap;

    &:nth-of-type(1) {
      .term,
      .description {
        padding-top: 0;
      }
    }
  }

  .term {
    display: table-cell;
    padding: 10px 10px 0 0;
    line-height: 30px;
    vertical-align: top;
  }

  .description {
    display: table-cell;
    padding-top: 12px;
    vertical-align: top;
  }

  .keystroke {
    display: inline-block;
    border: 1px solid #fff;
    min-width: 30px;
    height: 30px;
    margin-left: 4px;
    padding: 0 8px;
    border-radius: 2px;
    font-size: 10px;
    font-style: normal;
    text-align: center;
    line-height: 28px;
    letter-spacing: -0.18px;
    box-sizing: border-box;
    vertical-align: top;

    &:nth-of-type(1) {
      margin-left: 0;
    }

    .icon-text {
      display: inline-block;
      font-size: 17px;
      vertical-align: top;
    }
  }

  .icon-arrow-left {
    margin-right: 3px;
  }

  .icon-arrow-left:before,
  .icon-arrow-right:before {
    content: '';
    display: inline-block;
    width: 17px;
    height: 14px;
  }

  .icon-arrow-left:before {
    background: url('../../img/icon/icon_arrowLeft.svg') no-repeat center center;
  }

  .icon-arrow-right:before {
    background: url('../../img/icon/icon_arrowRight.svg') no-repeat center center;
  }

  .wrap-add-keystroke {
    position: relative;

    .add-description {
      position: absolute;
      right: 0;
      top: 100%;
      width: 100%;
      margin-top: 10px;
      text-align: right;

      > span {
        display: inline-block;
        margin: 0 5px;
        line-height: 30px;
      }
    }
  }
}
