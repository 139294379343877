/*
Thin: 100
Light: 300
Regular: 400
Medium: 500
Bold: 700
Black: 900
*/

input[type="text"] {
  box-sizing: border-box;

  &.input-text:not([class*="ant-input"]) {
    height: 24px;
    padding: 0 4px;
    border-radius: 2px;
    border: 2px solid #5ebeff;
    background-color: #fff;
  }

  &:focus {
    outline: none;
  }
}

[class*="button-"] {
  &:focus {
    outline: none;
  }
}

.button-color1 {
  color: #fff;
  background-color: #5f5f5f;

  &:hover {
    background-color: #848484;
  }
  &:active {
    background-color: #4a4a4a;
  }
  &:disabled {
    cursor: default;
    background-color: #c4c4c4;
  }
}

.button-color2 {
  color: #fff;
  background-color: #252525;

  &:hover {
    background-color: #545454;
  }
  &:active {
    box-shadow: 0 0 0 1px #5ebeff inset;
  }
  &:disabled {
    cursor: default;
    background-color: #252525;

    &:before,
    span {
      opacity: 0.3;
    }
  }
}

.button-color3 {
  color: #fff;
  background-color: #484848;

  &:hover {
    background-color: #737373;
  }
  &:active {
    cursor: default;
    background-color: #373737;
  }
}

.button-color4 {
  color: #0099ff;
  background-color: #fff;

  &:focus,
  &:hover {
    background-color: #e5e5e5;
  }

  &:active {
    cursor: default;
    background-color: #d3d3d3;
  }
}

.button-color5 {
  border-radius: 2px;
  border: solid 1px #0099ff;
  color: #0099ff;
  background-color: #fff;

  &:not(:disabled):hover,
  &:not(:disabled):active {
    background-color: #d6eeff;
  }

  &:active {
    cursor: default;
  }

  &:disabled {
    opacity: .5;
  }
}


.button-color6 {
  color: #fff;
  background-color: #0099ff;

  &:not(:disabled):hover,
  &:not(:disabled):active {
    background-color: #1389d8;
  }

  &:active {
    cursor: default;
  }

  &:disabled {
    opacity: .5;
  }
}

.button-color7 {
  color: #fff;
  background-color: #59c264;

  &:not(:disabled):hover,
  &:not(:disabled):active {
    background-color: #35a040;
  }

  &:active {
    cursor: default;
  }

  &:disabled {
    opacity: .5;
  }
}


[data-icon="true"]:before {
  content: '';
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
}

.checkbox:not([class*="ant-checkbox"]) {
  margin: 0 !important;
  color: #000;

  + .checkbox {
    margin-left: 24px !important;
  }
}
.icon-checkbox {
  padding: 0 6px 0 0 !important;
}


/* 팝업 */
.wrap-dialog {
  .wrap-dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .wrap-print-options {
    flex: none;
  }

  .dialog-content {
    font-size: 16px;
    line-height: 1.5;
    color: #000;

    div, p, dl, dt, dd, span {
      //line-height: 1.5;
      //color: #000;
    }
  }

  .dialog-actions {
    padding: 10px 24px;

    button[class*="button-color"] {
      min-width: 50px;
      height: 28px;
    }

    .MuiCheckbox-root {
      .MuiSvgIcon-root {
        font-size: 21px;
      }
    }
  }

  [contenteditable=true] {
    outline: none;
  }
}

p.text-feedback {
  font-size: 14px;
  line-height: 1.5;
  color: #f5222d;
}

.btn {
  transition: none !important;
}