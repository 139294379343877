@import 'editorVariables';

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video,
button, input[type="text"]:not([class*="ant-input"]), textarea {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans", "Noto Sans CJK KR", "Noto Sans KR", sans-serif;
  font-weight: 400;
}


#root {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;

  &.bg {
    background: $defaultBgColor;
  }
}

.editorAside {
  width: 258px;
  height: $layoutFlowHeight;
  border-top: 1px solid #373737;
  background: $defaultBgColor;
}

// layout
.editor-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  position: relative;
  min-width: 1440px;
  max-width: calc(100vw - 30px);
  width: 1440px;
  height: 100%;
  margin: 0 auto;
  color: $defaultTextColor;
  box-shadow: 0 0 25px #000;
  overflow-x: hidden;
  resize: horizontal;
}

.editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: $layoutHeaderHeight;
  padding: 0 20px;
  background: $defaultBgColor;
}

.editor-aside-info {
  flex: none;
  overflow-x: hidden;
  overflow-y: auto;
  order: 2;
  padding: 0 20px $playerHeight;
  @extend .editorAside;
}

.editor-aside-edit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: none;
  order: 4;
  @extend .editorAside;
}

.editor-aside-edit .wrap-scroll {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px;
}

.editor-workspace {
  flex: none;
  display: flex;
  flex-direction: column;
  order: 3;
  overflow: hidden;
  width: 924px;
  height: $layoutFlowHeight;
  color: $bodyTextColor;
  background: $bodyBgColor;
}
