$paragraphTimeHeight: 18px + 6px;
$warningBgColor: #f44336;
$warningTextColor: #fff;


.wrap-script {
  &[contenteditable=false] {
    opacity: .7;
  }

  .paragraph {
    display: flex;
  }

  .paragraph-content {
    > .info {
      &:before {
        content: attr(data-timestamp);
        display: inline-block;
        font-style: normal;
        user-select: none;
      }

      &.text-length {
        &:after {
          content: attr(data-text-length) "자";
          display: inline-block;
          margin-left: 5px;
          padding: 2px 4px;
          font-style: normal;
          color: #333;
          border-radius: 4px;
          line-height: 1;
          background-color: rgba(255, 255, 255, .5);
          user-select: none;
        }
      }
    }
  }

  .paragraph-aside {
    flex: none;
    //width: 198px;
    //padding: 0 40px 0 10px;
    font-size: 16px;
    text-align: right;
  }

  .line-break {
    .paragraph-aside {
      opacity: 0;
    }
  }
}

.workspace-edit {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .wrap-script {
    flex: 1 0;
    overflow: hidden;
  }

  .paragraph {
    //display: flex;
    padding-top: 36px + $paragraphTimeHeight;
    padding-bottom: 48px - 36px;
    padding-right: 126px;
    line-height: 1.5;

    &.warning-too-long {
      position: relative;
      padding-top: 60px !important;
      padding-bottom: 60px !important;
      background: #8B4A4A;
    }

    &.line-break {
      padding-top: 25px;
    }
  }

  [class^="notice-too-long-"] {
    padding: 5px 10px;
    color: $warningTextColor;
    background: $warningBgColor;

    .icon {
      display: inline-block;

      &:before {
        content: '☹';
        display: inline-block;
        margin-right: 5px;
        font-size: 30px;
        line-height: .5;
        vertical-align: top;

        .isWindows & {
          font-size: 20px;
          line-height: .8;
        }
      }
    }

    .keystroke {
      margin: 0 2px;
      padding: 0 3px;
      border-radius: 3px;
      color: $warningBgColor;
      background-color: $warningTextColor;
    }
  }

  .notice-too-long-fix {
    position: fixed;
    top: 48px;
    left: 0;
    z-index: 10;
    width: 100%;
    min-height: 30px;
    text-align: center;
    cursor: pointer;
  }

  .notice-too-long-block {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;

    &.top {
      top: 0;
    }

    &.bottom {
      bottom: 0;
    }
  }

  .paragraph-aside {
    //flex: none;
    width: 198px;
    padding: 0 40px 0 10px;
    //font-size: 16px;
    //text-align: right;

    .MuiInputBase-root {
      max-width: 100%;
    }

    .name-speaker {
      padding: 3px 8px 5px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      color: #a4a4a4;
      line-height: 22px;
      background-color: transparent;
      text-align: right;

      &:hover {
        background-color: #5f5f5f;
      }

      &:focus {
        outline: none;
      }

      &:before {
        content: attr(data-speaker);
      }
    }
  }

  .paragraph-content {
    color: $editorDefaultColor;

    &:before {
      //  content: attr(data-timestamp);
      //  display: block;
      //  font-style: normal;
      color: #e5e5e5;
      //  user-select: none;
    }

    .wrap-words {
      padding-top: 3px;
      font-size: 18px;
      line-height: 1.56;
      word-break: break-all;

      // 단어 구분하여 확인해야 하는 작업시 필요하여 주석
      /*
      span[data-slate-node="text"] {
        background: rgba(255, 255, 0, 0.2);
      }
      span[data-slate-node="text"]:nth-child(odd) {
        background: rgba(2, 255, 255, 0.3);
      }*/
    }

    .word {
      &[data-unrecognizable] {
        color: $textColor02;
        background: rgba(255, 0, 0, 0.5);
      }

      &[data-confidence=false] {
        color: #ff8383;
      }
    }

    [data-progress="past"] {
      color: #fff;
    }

    [data-progress="current"] {
      color: #5ebeff;
    }
  }
}

