$paragraphTimeHeight: 18px + 6px;
$warningBgColor: #f44336;
$warningTextColor: #fff;

.dialog-content-copy {
  .MuiDialog-paperScrollPaper {
    height: calc(100% - 64px);
  }

  .wrap-dialog-title {
    padding: 10px 31px 13px 24px;
  }

  .dialog-title {
    font-size: 16px;
    line-height: 1.5;
    color: #000;
  }

  .dialog-content {
    display: flex;
    overflow: hidden !important;
    height: 100%;
    padding: 0;

    textarea {
      flex: 1 0 auto;
      height: 100% !important;
      padding: 34px 24px 62px;
      border: none;
      font-size: 18px;
      font-style: normal;
      font-weight: normal;
      box-sizing: border-box;
      line-height: 1.5;
      resize: none;
      outline: none;
    }
  }
}
