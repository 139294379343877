.redux-toastr {
  .toastr {
    .rrt-left-container {
      .rrt-holder {
        top: 0;
        margin-top: 0;
      }
    }

    .rrt-middle-container {
      width: calc(100% - 105px);
    }

    .close-toastr {
      width: 30px;
      height: 30px;
    }
  }

  .top-center,
  .bottom-center {
    margin-left: 0;
    transform: translateX(-50%);
  }

  .top-left,
  .top-right,
  .top-center,
  .bottom-left,
  .bottom-right,
  .bottom-center {
    width: auto;
    max-width: 500px;
    min-width: 350px;
  }

  .rrt-middle-container {
    .rrt-text {
      line-height: 1.3;
      white-space: pre-line;
    }
  }

  .rrt-confirm {
    .rrt-message {
      font-size: 1.15rem;
      line-height: 1.3;
      white-space: pre-line;
    }
  }


  .toastr {
    &.notice-script-updating {
      width: 500px;
    }
  }
}

// title 만 있는 경우(content 는 문자열 제공) - 텍스트 상하 중앙정렬
.redux-toastr div[class*="middle-container"] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70px;
}