@import "common";
@import "../../sass/toastr";
@import "editorLayout";
@import "workspaceEdit";
@import "workspacePlayer";
@import "modalHotkey";
@import "modalContentCopy";
@import "modal";
@import "modalHistory";
@import "modalSpellCheck";

.edit-area {
  .edit-text {
    cursor: text;

    &:hover {
      outline: 1px solid rgba(255, 255, 255, 0.8);
    }
  }

  .edit-input {
    width: 100%;
    border: none;
    font: inherit;
    color: inherit;
    background: transparent;
    outline: none;
  }

  textarea {
    resize: vertical;
  }
}

.dim {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, .5);

  .MuiLinearProgress-root {
    width: 300px;
    margin-top: 10px;
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: #0099ff;
  }
}

// header
.editor-header {
  .wrap-file-title {
    overflow: hidden;
    flex: 1 1 auto;
    padding: 1px 20px 1px 1px;
  }

  .btn-home {
    font-size: 20px;
  }

  .file-title {
    @extend .textEllipsis;
    display: inline-block;
    max-width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    cursor: pointer;
  }

  .wrap-aside {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    //width: 238px;
    white-space: nowrap;
  }

  .msg-work-save {
    @extend .textEllipsis;
    flex: 1 0 auto;
    max-width: 160px;
    font-style: normal;
    line-height: 1.5;
  }

  [class^='button-'] {
    min-width: 58px;
    height: 28px;
    margin-left: 6px;
    padding: 0 5px;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
  }
}

.button-work-save:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  background: url('../../img/button/button_work_save.svg') no-repeat center center;
}

.button-work-copy:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  background: url('../../img/button/button_work_copy.svg') no-repeat center center;
}

.button-show-history:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  background: url('../../img/button/button_show_history.svg') no-repeat center center;
}

.button-sync:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  background: url('../../img/button/button_sync.svg') no-repeat center center;
}

.button-delete-all-content:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  background: url('../../img/button/button_delete.svg') no-repeat center center;
}

.button-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    display: inline-block;
  }
}

.button-script-show {
  &:before {
    width: 14px;
    height: 14px;
    margin-right: 1px;
    background: url('../../img/button/button_script_show.svg') no-repeat center center;
  }
}

.button-rollback {
  &:before {
    width: 14px;
    height: 14px;
    margin-right: 1px;
    background: url('../../img/button/button_rollback.svg') no-repeat center center;
  }
}

// aside
.box-aside {
  padding: 18px 0 17px;
  line-height: 1.5;
  color: #a4a4a4;

  &:not(:first-child) {
    border-top: 1px solid #373737;
  }

  .title {
    font-size: inherit;
    font-weight: 500;
    color: $defaultTextColor;
  }

  .content {
    a[target="blank"] {
      color: #0099ff;
    }
  }

  .content,
  .input-value,
  .description {
    overflow-wrap: break-word;
    word-break: keep-all;
  }

  &[data-direction="column"] {
    .title {
      margin-bottom: 6px;
    }

    .content {
      //overflow: hidden;
      min-height: 20px;
      margin: -1px;
      padding: 1px;
    }

    // '내용 설명' 은 사용자가 입력한 그대로(줄바꿈) 출력되도록 추가.
    &.aside-description {
      .content {
        white-space: pre-wrap;
        word-break: break-word;
      }
    }
  }

  &[data-direction="row"] {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;

    .title {
      flex: none;
      margin-right: 6px;
      white-space: nowrap;
    }

    .content {
      flex: 0 1 auto;
      overflow: hidden;
      width: 100%;
      min-height: 18px;
      padding: 1px;
      text-align: right;
      box-sizing: border-box;
      overflow-wrap: break-word;
      word-break: keep-all;
    }

    .edit-input {
      text-align: right;
    }
  }

  input[type="datetime-local" i] {
    outline: none;

    &::-webkit-calendar-picker-indicator {
      filter: invert(.5);
    }
  }

  .link-down {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.aside-speaker {
  position: relative;

  [class^="button-speaker-"] {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 2px;
  }

  .list-speaker {
    position: relative;
    padding: 18px 24px 17px 0;

    &:not(:nth-of-type(1)) {
      border-top: 1px solid #373737;
    }

    .item {
      display: flex;
      align-items: flex-start;
      min-height: 24px;
      color: $defaultTextColor;
    }

    .term {
      flex: none;
      margin-right: 10px;
      line-height: 24px;
    }

    .description {
      flex: 0 1 auto;
      overflow: hidden;
      width: 100%;
      padding: 1px;
      color: $textColor02;
      box-sizing: border-box;
    }

    .input-value {
      min-height: 24px;
      padding-top: 2px;
    }

    .input-text:not([class*="ant-input"]) {
      width: 100%;
      font-size: inherit;
      color: #000;
    }
  }
}

.button-speaker-create {
  top: 16px;
  right: -6px;
}

.button-speaker-create:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url('../../img/button/button_speaker_create.svg') no-repeat center center;
}

.button-speaker-delete {
  top: 15px;
  right: -6px;
}

.button-speaker-delete:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 2px;
  border-radius: 2px;
  background: url('../../img/button/button_speaker_delete.svg') no-repeat center center;
}

.toastr {
  [id^="dialogDesc-"] {
    &[class*="-text"] {
      word-break: keep-all;
    }
  }
}

/* 사이드 컨텐츠 여백 - 맞춤법 검사, 타이핑 완료 */
.wrap-aside-inner {
  padding: 20px;
}