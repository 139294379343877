@import '../font/NotoSansKR-subset/notoSansKR.css';

/*
Thin: 100
Light: 300
Regular: 400
Medium: 500
Bold: 700
Black: 900
*/

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video,
button, input[type="text"]:not([class*="ant-input"]), textarea {
  font-family: "Noto Sans", "Noto Sans CJK KR", "Noto Sans KR", sans-serif;
  font-weight: 400;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}


/*  */
html, body {
  width: 100%;
  height: 100%;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

header, section, aside, div {
  box-sizing: border-box;
}

/* common */
.text-hidden {
  overflow: hidden;
  position: absolute;
  z-index: -10;
  width: 1px;
  height: 1px;
}

.noselect {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

button {
  border: none;
}

button:not(:disabled) {
  cursor: pointer;
}

#root {
  width: 100%;
  height: 100%;
}

/* sentry */
#sentry-error-embed-heading {
  margin-bottom: 15px
}

#sentry-error-embed-heading + p > span {
  display: block;
}


a[target="_blank"][rel="noreferrer"]:not([download]) {
  text-decoration: underline;
}

/* cra 버전 문제? tailwindcss 적용 안됨.(CRA 새롭게 생성시 문제 없음. 현재 버전에서 적용 안됨) */
.inline-block {
  display: inline-block;
}

.m-0 {
  margin: 0 !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mb-20px {
  margin-bottom: 20px;
}

/* font */
.bold {
  font-weight: bold;
}

.align-left {
  text-align: left;
}

.bg-transparent {
  background: transparent;
}

/* text-color */
.text-point {
  color: #0099ff !important;
  font-weight: inherit;
}


/* */
.logo {
  background: url("../img/common/logo.svg") no-repeat center center;
  background-size: 100% auto;
}


.textEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.table-list th, .table-list td {
  overflow-wrap: break-word;
  word-break: keep-all;
}

.table-list td .file-name {
  display: inline-block;
  word-break: break-all;
  text-align: left;
}

/* antd - table */
.ant-table-thead th {
  line-height: 1.1;
}


/* background */
.bg-transparent {
  background: transparent;
}

/* width */
.w-full {
  width: 100%;
}