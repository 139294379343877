$defaultBgColor: #252525;
$bodyBgColor: #373737;

$defaultTextColor: #fff;
$bodyTextColor: #a4a4a4;
$textColor02: #0099ff;

$editorDefaultColor: #a4a4a4;
$editorColor: #fff;
$editorCurrentColor: #5ebeff;/* 현재 단어 */
$editorImpossibleColor: #ff8383;/* 인식 불가 */


$playerHeight: 60px;
$layoutHeaderHeight: 48px;
$layoutFlowHeight: calc(100% - #{$layoutHeaderHeight});

$workspaceMinWidth: calc(924px - 57px - 58px);

.textEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
