$paragraphTimeHeight: 18px + 6px;
$dateColor: #0099ff;
$warningTextColor: #fff;


.wrap-dialog.dialog-column {
  &.isFetching {
    .wrap-content {
      position: relative;

      .wrap-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: #fff;
      }
    }
  }

  .MuiDialog-paperScrollPaper {
    min-height: calc(100% - 64px);
  }

  .sec-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-shrink: 0;
    height: 34px;
    padding-bottom: 10px;
  }

  .sec-title {
    align-self: flex-start;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }

  .dialog-content {
    .sec-content {
      .wrap-content {
        padding: 20px 16px;
      }
    }

    .wrap-content {
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      height: 100%;
      border: solid 1px #c4c4c4;
      background-color: #fff;
    }
  }


  .dialog-actions {
    .button {
      min-width: 80px;

      &:not(:first-of-type) {
        margin-left: 12px;
      }
    }
  }
}