$paragraphTimeHeight: 18px + 6px;
$dateColor: #0099ff;
$warningTextColor: #fff;


.dialog-spell-check {
  .text-wrong-word {
    &.hover,
    &:hover {
      text-decoration: underline;
    }
  }

  .text-error-spell {
    color: #F5222D !important;
  }

  .text-error-space {
    color: #000 !important;
  }

  .text-error-doubt {
    color: #FA8C16 !important;
  }

  .text-error-changed {
    font-weight: 700;
    color: #000;
    cursor: text;
    user-select: text;

    &:hover {
      text-decoration: none;
    }
  }


  .dialog-content {
    font-size: 14px;
    line-height: 20px;

    .sec-content {
      @extend .historyLayout;
      flex-grow: 1;
      margin-right: 16px;

      .wrap-content {
        > p:not(:first-of-type) {
          margin-top: 20px;
        }
      }
    }

    .sec-aside {
      @extend .historyLayout;
      flex-shrink: 0;
      width: 314px;
    }
  }

  .sec-content {
    p {
      color: #8C8C8C;
    }
  }

  .sec-aside {
    .sec-header {
      .btn {
        font-size: 12px;
        color: #8C8C8C;
        line-height: 24px;
        background: transparent;
      }
    }

    .text-no-data {
      padding: 80px 0;
      text-align: center;
    }
  }

  .list-error-word {
    > li {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 14px 4px 15px 12px;
      border-bottom: 1px solid #C4C4C4;

      > span {
        &.text {
          flex: 1 0 105px;

          button {
            background: transparent;
            text-align: left;
            word-break: break-all;
          }
        }

        &.icon-arrow {
          margin: 0 8px;
        }
      }

      .text-output {
        font-weight: 700;

        &.text-error-doubt {
          cursor: default;
        }
      }
    }
  }

  .btn-change-output {
    border: none;

    svg {
      fill: #999;
    }
  }

  .dialog-actions {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
