$buttonControlWidth: 36px;
$buttonControlHeight: 36px;
.rail {
  position: relative;
}

.react-player {
  position: absolute;
  left: 0;
  z-index: -1;
  bottom: 100%;
  width: 100% !important;
  height: 100% !important;
}

.workspace-player {
  box-shadow: 0 -4px 2px 0 rgba(0, 0, 0, 0.05);
  background-color: #484848;

  > .inner {
    display: flex;
    align-items: center;
    position: relative;
    //width: $workspaceMinWidth;
    max-width: 924px;
    height: $playerHeight;
    margin: 0 auto;
    padding: 0 57px;
    color: $defaultTextColor;
  }

  [data-icon="true"]:before {
    width: 100%;
    height: 100%;
  }

  .wrap-rail {
    order: 4;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 452px;
    margin-left: 41px;

    .time-elapsed,
    .time-duration {
      font-style: normal;
      line-height: 1;
    }

    .rail {
      flex: none;
      width: 100%;
    }
  }

  .wrap-speed {
    flex: none;
    order: 5;
    display: flex;
    flex-direction: column-reverse;
    margin: 0 30px 0 48px;

    .speed-value {
      display: flex;
      align-items: center;
    }

    .term {
      flex: none;
      margin-bottom: 3px;
    }

    .description {
      margin-left: 4px;
      color: $textColor02;
    }

    .wrap-select {
      height: 0;
      outline: 1px solid blue;
    }
  }
}

.button-play {
  flex: none;
  order: 2;
  width: $buttonControlWidth;
  height: $buttonControlHeight;
  margin: 0 11px;

  &:before {
    background-image: url('../../img/button/control/button_play.svg');
  }
}

.button-pause {
  flex: none;
  order: 2;
  width: $buttonControlWidth;
  height: $buttonControlHeight;
  margin: 0 11px;

  &:before {
    background-image: url('../../img/button/control/button_pause.svg');
  }
}

.button-rewind {
  flex: none;
  order: 1;
  width: $buttonControlWidth;
  height: $buttonControlHeight;

  &:before {
    background-image: url('../../img/button/control/button_rewind.svg');
  }
}

.button-fast-forward {
  flex: none;
  order: 3;
  width: $buttonControlWidth;
  height: $buttonControlHeight;

  &:before {
    background-image: url('../../img/button/control/button_fast_forward.svg');
  }
}

.button-rewind,
.button-fast-forward {
  position: relative;

  .number {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 10px;
    transform: translate(-50%, -50%);
  }
}

.button-hotkey {
  flex: none;
  order: 6;
  width: $buttonControlWidth;
  height: $buttonControlHeight;

  &:before {
    background-image: url('../../img/button/button_hotkey.svg');
  }
}


