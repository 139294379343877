$paragraphTimeHeight: 18px + 6px;
$dateColor: #0099ff;
$warningTextColor: #fff;

.historyLayout {
  display: flex;
  flex-direction: column;
}

.dialog-history {
  &.isFetching {
    .sec-history-list .wrap-content {
      position: relative;

      .wrap-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: #fff;
      }
    }
  }

  .sec-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-shrink: 0;
    height: 34px;
    padding-bottom: 10px;
  }

  .sec-title {
    align-self: flex-start;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }

  .wrap-content {
    height: 510px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 16px;
    border: solid 1px #c4c4c4;
    background-color: #fff;
  }

  .wrap-words {
    word-break: break-word;
  }

  .text-date {
    font-size: 12px;
    color: $dateColor !important;
    letter-spacing: -0.18px;
  }

  .sec-history-list {
    @extend .historyLayout;
    flex-shrink: 0;
    order: 2;
    width: 206px;
    font-size: 12px;

    .wrap-content {
      padding-top: 10px;

      .item {
        padding-bottom: 13px;

        &:not(:first-child) {
          padding-top: 8px;
          border-top: 1px solid #c4c4c4;
        }
      }
    }

    .text-date {
      margin-top: 4px;
    }

    .wrap-buttons {
      display: flex;
      margin-top: 8px;
    }

    [class*="button-"] {
      width: 84px;
      height: 20px;
      border-radius: 3px;
      font-size: 10px;
      line-height: 1;
      letter-spacing: -0.15px;


      &:not(:first-child) {
        margin-left: 6px;
      }
    }

    .icon-sync {
      font-size: 15px;
    }

    .tag-sync {
      height: auto;
      margin-left: 5px;
      padding: 3px 5px;
      font-size: 10px;
      line-height: 1;

      svg {
        margin-right: 3px;
        path {
          fill: red
        }
      }
    }
  }

  .sec-history-script {
    @extend .historyLayout;
    flex-grow: 1;
    order: 1;
    margin-right: 10px;

    .text-date {
      margin-right: 16px;
    }
  }

  .no-data {
    font-size: 12px;
    letter-spacing: -0.18px;
    color: #848484 !important;
  }

  .paragraph {
    padding-top: 20px;

    &:last-child {
      margin-bottom: 20px;
    }
  }

  .paragraph-aside {
    width: calc(140px - 16px);
    padding-right: 20px;
  }

  .paragraph-content {
    font-size: 18px;

    &:before {
      font-size: 12px;
      color: $dateColor;
    }
  }
}
